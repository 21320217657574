const routes = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "main" */ '@/layouts/MainLayout'),
    children: [
      {
        path: '',
        name: 'HomePage',
        component: () =>
          import(/* webpackChunkName: "main" */ '@/views/HomePage'),
      },
      {
        path: 'signup',
        name: 'SignupPage',
        component: () =>
          import(/* webpackChunkName: "main" */ '@/views/SignupPage'),
      },
      {
        path: 'privacy-policy',
        name: 'PrivacyPage',
        component: () =>
          import(/* webpackChunkName: "main" */ '@/views/PrivacyPage'),
      },
    ],
  },
]

module.exports = routes
