import Vue from 'vue'
import App from './App.vue'
import { createRouter } from './router'
//import VueGtm from 'vue-gtm'
import Meta from 'vue-meta'
import { BootstrapVue } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'

//CSS import
import '@/assets/styles/global.scss'

Vue.config.productionTip = false

/*Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  enabled: false, //window.__PRERENDER_INJECTED ? false : true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.NODE_ENV === 'production' ? false : true, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ['intercept'], // If router, you can exclude some routes name (case insensitive) (optional)
})*/

Vue.use(Meta)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)

export function createApp() {
  // create router instance
  const router = createRouter()
  const app = new Vue({
    // inject router into root Vue instance
    router,
    render: h => h(App),
  })
  // return both the app and the router
  return { app, router }
}

const { app, router } = createApp()

router.onReady(() => {
  app.$mount('#app')
})
